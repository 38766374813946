import { GraphQLClient } from 'graphql-request';

const environment =
  process.env.REACT_APP_ENV || process.env.REACT_APP_LOCAL || 'local';

export const endpoint =
  environment === 'production'
    ? process.env.REACT_APP_PROD_API
    : environment === 'staging'
    ? process.env.REACT_APP_STAGE_API
    : environment === 'local'
    ? process.env.REACT_APP_LOCAL_API
    : process.env.REACT_APP_DEV_API;

const graphQLClient = new GraphQLClient(endpoint + '/graphql');

export default graphQLClient;
