/* eslint-disable @typescript-eslint/no-unused-vars */
import { instanceToPlain, Transform } from 'class-transformer';
import { DateHelper } from '../../utils/date-helper';

export class BaseModel {
  @Transform(({ value }) => parseInt(value)) id: number;
  @Transform(({ value }) => DateHelper.parse(value)) createdAt: Date;
  @Transform(({ value }) => DateHelper.parse(value)) updatedAt: Date;

  get json() {
    return instanceToPlain(this);
  }
}
