import { providers } from 'ethers'
import { InjectedConnector } from '@web3-react/injected-connector'

export const getLibrary = (provider: any, connector: any) => {
  const library = new providers.Web3Provider(provider)
  return library
}

export const injected = new InjectedConnector({
  supportedChainIds: [80001, 137],
})
