/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useHistory } from "react-router";

import BtnClose from "../btn-close/btn-close";

import "./drawer.scss";
import {Paths} from "../../../utils";

export type DrawerProps = {
  openDrawer: boolean;
  onClose: () => void;
};

const Drawer: React.FC<DrawerProps> = ({ onClose, openDrawer }) => {
  const [isActive, setIsActive] = React.useState(false)

  const links = [
    {
      title: "Waiting list",
      class: "link text-white",
      link: "/",
    },
    {
      title: "Approved list",
      link: `${Paths.approvedList}/?approved=true`,
      class: "link text-white",
    },
    {
      title: "SafeList",
      link: Paths.safelist,
      class: "link text-white",
    },
    {
      title: "Authorized users",
      link: Paths.authUsers,
      class: "link text-white",
    },
  ];

  const history = useHistory();
  const GoToPage = (arr: any) => {
    onClose()
    history.push(arr);
  };

  return (
    <div
      className={
        "draw-container" + (openDrawer ? " d-flex" : " d-none")
      }
    >
      <div className="draw-container-ele">
        <ul className={'nav'}>
          {links.map((link, lindex) => (
            <li
              key={"li_" + lindex}
              className={`nav-item ${openDrawer
                ? `item-show-animation-${lindex}`
                : `item-close-animation-${lindex}`
                }`}
            >
              {link.link !== "" ? (
                <a
                  onClick={() => GoToPage(link.link)}
                  target={link.title === 'Docs' ? "_blank" : '_self'}
                  className={`nav-link ${link.class} ${isActive && 'active'}`}
                >
                  {link.title}
                </a>
              ) : (
                <a
                  onClick={() => setIsActive(!isActive)}
                  className={`nav-link ${link.class} `}
                >
                  {link.title}
                </a>
              )}
            </li>
          ))}
        </ul>
      </div>

      <BtnClose onClose={onClose} showClose={openDrawer} />
    </div>
  );
};

export default Drawer;
