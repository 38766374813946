/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from "react";
import {
  CrossIconContainer,
  ModalBorder,
  ModalContainer, ModalWrapper
} from "../../account/pool-list/modals/style";
import {CloseIconNew} from "../../../images";
import {ButtonCt, Input, Header} from "./style";

interface Props {
  show: boolean;
  closeModal: () => void;
}

const AddUserModal: React.FC<Props> = (props : Props) => {
  const { show, closeModal } = props;
  const [secondStep, showSecondStep] = useState<boolean>(false);
  const [poolName, setPoolName] = useState<string>('');
  const [wallet, setWallet] = useState<string>('');

  const addUser = () => {
    showSecondStep(true);
  }

  return (
    <>
      <ModalWrapper show={show}>
        <ModalBorder>
          <ModalContainer>
            <Header>
              Add User

              <CrossIconContainer onClick={closeModal}>
                <img src={CloseIconNew.default} alt="close"/>
              </CrossIconContainer>
            </Header>

            {!secondStep &&
            <>
                <Input>
                    <div className="input-title">
                        Pool Name
                    </div>
                    <label htmlFor="pool-name" className="input-label">
                        <input
                            type='text'
                            id={'pool-name'}
                            value={poolName}
                            onChange={event => setPoolName(event.target.value)}
                            placeholder="Enter pool name"
                        />
                    </label>
                </Input>

                <Input>
                    <div className="input-title">
                        Wallet Address
                    </div>
                    <label htmlFor="wallet-address" className="input-label">
                        <input
                            type='text'
                            id={'wallet-address'}
                            value={wallet}
                            onChange={event => setWallet(event.target.value)}
                            placeholder="Enter wallet address"
                        />
                    </label>
                </Input>

                <ButtonCt>
                    <a onClick={closeModal} className="btn-action-link bottom-border-none">
                        Cancel
                    </a>

                    <a onClick={addUser} className="btn-action-link">
                        Add
                    </a>
                </ButtonCt>
            </>}
            {
              secondStep &&
                <>
                    Smart Contract Call
                </>
            }
          </ModalContainer>
        </ModalBorder>
      </ModalWrapper>
    </>
  )
}

export default AddUserModal;
