//@ts-ignore
const Images = require.context("./images", true);

export const RoyaleLogo = Images("./royale-logo.svg");

export const UserIcon = Images("./user-icon.svg");

export const HistoryIcon1 = Images("./history1.svg");

export const HistoryIcon2 = Images("./history2.svg");

export const HistoryIcon3 = Images("./history3.svg");

export const HistoryIcon4 = Images("./history4.svg");
export const bgIcon = Images("./bg.png");
export const checkIcon = Images("./checkmark.png");
export const holo1Icon = Images("./holo1.png");
export const holo2Icon = Images("./holo2.png");

export const SelectArrow = Images("./select-arrow.svg");

export const RightArrow = Images("./right-arrow.svg");

export const QuestionCircle = Images("./question-circle.svg");

export const MetamaskIcon = Images("./metamask-logo.svg");

export const AttentionIcon = Images("./attention-icon.svg");

export const CheckedIcon = Images("./checked-icon.svg");

export const AuthereumIcon = Images("./authereum-icon.svg");

export const BurnerIcon = Images("./burner-icon.svg");

export const UniLoginIcon = Images("./unilogin-icon.svg");

export const WalletConnectIcon = Images("./wallet-connect-icon.svg");

export const MewWalletIcon = Images("./mewwallet-icon.svg");

export const CrossIcon = Images("./cross-icon.svg");

// export const CloseIcon = Images("./close-icon.png");

export const GroupIcon = Images("./Group-icon.png");
export const RoundIcon = Images('./round-bg.png');

export const UploadIcon = Images("./upload-icon.svg");

export const WhitelistBtn = Images("./Rectangle.png");

export const CopyIcon = Images("./copy-icon.svg");

export const CylinderIcon = Images("./cylinder-icon.svg");

export const RoyaAnalyticsImg = Images("./roya-analytics.svg");

export const RoyaleFlushImg = Images("./roya-splash.png");

export const RoyaleKingImg = Images("./king-lots.png");

export const RoyaleQueenImg = Images("./queen-lots.png");

export const MinusIcon = Images("./minus-icon.svg");

export const PlusIcon = Images("./plus-icon.svg");

// export const MenuIcon = Images("./menu.svg");

// export const TwitterIcon = Images("./icon-twitter.png");

// export const TelegramIcon = Images("./icon-telegram.png");

// export const MediumIcon = Images("./icon-medium.png");

export const QueenNftICon = Images("./royale-queen-NFT.jpg");

export const NetworkStatBg = Images("./network-stats-bg.svg");

export const GraphLineGreen = Images("./graph-line-green.png");

export const GraphLineRed = Images("./graph-line-red.png");

export const ArrowGreen = Images("./arrow-green.svg");

export const ArrowRed = Images("./arrow-red.svg");

export const RightArrowWhite = Images("./arrow-white.svg");

export const EtherScanIcon = Images("./etherscan-icon.svg");

export const BscScanIcon = Images("./bscscan-icon.svg");

export const MaticScanIcon = Images("./matic-icon.svg");

export const KingLotIcon = Images("./king-lots.png");

export const NetworkArrow = Images("./network-arrow.svg");

export const ConvertArrow = Images("./convert-arrow.svg");

export const GradientBorder = Images("./gradient-border.png");

export const CircleGreen = Images("./circle-green.png");

export const CirclePurple = Images("./circle-purple.png");

export const CircleYellow = Images("./circle-yellow.png");

export const allTierIcon = Images("./Group 427318724 (1).png")
export const roadMapIcon = Images("./roadmapslide.png")



/////////////////////////////////////////////////////////////////////////////


export const RoyaLogoIcon = Images("./Logo.png");

export const MenuIcon = Images("./menu-icon.png");

export const TwitterIcon = Images("./icon-twitter.png");

export const TelegramIcon = Images("./icon-telegram.png");

export const MediumIcon = Images("./icon-medium.png");

export const InstagramIcon = Images("./icon-instagram.png");

export const TitokIcon = Images("./icon-tiktok.png");

export const LikeIcon = Images("./icon-like.png");

export const CommentIcon = Images("./icon-comment.png");

export const BottomLeftBg = Images("./bottom-left.png");

export const TopRightImg = Images("./top-right.png");

export const LeftMiddleImg = Images("./left-middle.png")

export const RightMiddleImg = Images("./right-middle.png")

export const RightBottomImg = Images("./right-bottom.png")

export const VideoFile = Images("./animation-bg.webm");

export const CloseIcon = Images("./close-icon.png");

export const CloseIconNew = Images("./close-icon-new.png");

export const LogoIcon = Images("./Logo.png");

export const CoverIcon = Images("./cover.jpg");

export const SideBar = Images("./sidebar-img.png");

export const Coingecko = Images("./coingecko.svg");

export const MarketIcon = Images("./market.svg");

export const OptomizerIcon = Images("./optimiser-icon.png");

export const GamingIcon = Images("./gaming-icon.png");

export const RptIcon = Images("./rpt-icon.png");

export const StakeDynamicIcon = Images("./stake-dynamic-img.png");

export const StackIcon = Images("./stake-img.png");

export const UnstackIcon = Images("./unstake-img.png");

export const PeriodIcon = Images("./period-icon.png");

export const FooterIcon = Images("./footer-logo.png");

export const logodropdown = Images("./logo-dropdown.png");

export const ImgIcon = Images("./img100.png");

export const metamasklogo = Images("./metamasklogo.png");

export const modalclose = Images("./modal-close.png");

export const walletgroup = Images("./walletgroup.png");

export const connect = Images("./connect.png");

export const BannerIcon = Images("./banner-text-img.png");

export const GameIcon = Images("./game-logo.png");

export const mediaTarget = Images("./media-target.png");

export const countCircle = Images("./count-circle.png");


export const CheckmarkIcon = Images("./checkmark.png");

export const Image101Icon = Images("./image101.png");

export const Image102Icon = Images("./image102.png");

export const TierIcon = Images("./tier-img.png");

export const Tier01Icon = Images("./tier-img01.png");

export const Tier02Icon = Images("./tier-img02.png");

export const Tier03Icon = Images("./tier-img03.png");

export const Tier04Icon = Images("./tier-img04.png");

export const Tier05Icon = Images("./tier-img05.png");

export const whiteDropdown = Images("./white-down-arrow-png.png")

export const IncreaseIcon = Images("./increase-icon.png");

export const PolygonIcon = Images("./polygon.png");

export const Image01Icon = Images("./01.png");

export const Image02Icon = Images("./02.png");

export const Image03Icon = Images("./03.png");

export const Image04Icon = Images("./04.png");

export const Image05Icon = Images("./05.png");

export const Image06Icon = Images("./06.png");

export const Image07Icon = Images("./07.png");

export const Image08Icon = Images("./08.png");

export const Image09Icon = Images("./09.png");

export const Image10Icon = Images("./10.png");

export const Image11Icon = Images("./11.png");

export const Image12Icon = Images("./12.png");

export const Image13Icon = Images("./13.png");

export const Image14Icon = Images("./14.png");

export const Image15Icon = Images("./15.png");

export const Image16Icon = Images("./16.png");

export const EthIcon = Images("./ethereum.png");

export const BinanceIcon = Images("./binance.png");

export const Team03Icon = Images("./team-img03.jpg");

export const Team01Icon = Images("./team-img01.jpg");

export const Team02Icon = Images("./team-img02.jpg");

export const Newgamesidebar = Images("./Rectangle-1611.png");

export const topbackcover = Images("./Rectangle-1623.png");

export const topbackcover1 = Images("./Rectangle-1616.png");

export const topbackcover2 = Images("./Rectangle-1636.png");

export const Leftbackcover = Images("./Group-481907.png");

export const Rightbackcover = Images("./Group-481908.png");

export const SETTLER = Images("./image-126.png");

export const MERCHANT = Images("./image-125.png");

export const MONARCH = Images("./Monarch.png");

export const ARCHON = Images("./image-127.png");

export const KNIGHT = Images("./image-128.png");

export const confirmationlogo = Images("./confirmationlogo.png");

export const bg2Icon = Images("./bg-2.png");

export const darkIcon = Images("./Frame 481903.png");

export const completeIcon = Images("./Frame 481920.png");

export const submitIcon = Images("./submit.png");

export const dropdownicon = Images('./dropdownicon.svg');

export const headerHamburger = Images('./hamburger.svg');

export const SubtractIcon = Images('./subtract.svg');

export const FaceBookLogo = Images("./facebook.svg");

export const LinkedinLogo = Images("./linkedin.svg");

export const TwitterLogo = Images("./twitter.svg");

export const HomeHeroImg = Images("./home-background.svg");

export const CompanyPic = Images("./company-picture.svg");

export const DefaultGameImage = Images("./default-game-image.svg");

export const DefaultImageIcon = Images("./default-image-icon.png");

export const DefaultVideoImage = Images("./default-video-icon.png");

export const FullInfoImage = Images("./full-info-image.svg");

export const ProjectAvatarImage = Images("./project-avatar.png");

export const FacebookIcon = Images("./facebook-2.svg");


export const BrowserIcon = Images("./web-icon.svg");
export const CheckMarkIcon = Images("./selectmark.png");
// export const CheckMarkIcon = Images("./check-mark.svg");
export const SelectMarkIcon = Images("./selectmark.png");

export const PoolSelect = Images("./pool-select.svg");
/////////////////////////////////////////////////////////////////////////////
export const UploadIcon1 = Images('./upload.svg');

export const GreyPlayIcon = Images('./grey-play-button.svg');

export const ArrowLeft = Images('./arrow-left.svg');


export const CloseBtn=Images("./closeBtn.png");
export const Coinbase=Images("./Coinbase.png");
export const TrustWallet=Images("./TrustWallet.png");
export const MetamaskLogo=Images("./metamasklogo.png");
export const Modalbg=Images("./modalbg.png");
export const CardDefault=Images("./card-default.png");
export const CardSelect=Images("./card-select.png");