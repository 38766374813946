/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from "react";
import { useHistory } from "react-router";
import {
  StoreageKey,
  Storage,
} from "../../utils";
import { useSelector } from "react-redux";
import { useWeb3React } from "@web3-react/core";
import Modal from "../modal";
import LoginModalContainer from "../login-modal";
import wallet from "../../utils/wallet";
import { login } from "../../logic/actions";
import { FooterIcon, headerHamburger } from "../../images";
import Drawer from "./drawer/drawer";
import { Admin } from "../../core/models/admin";
import store from "../../logic/reducers";
import { toast } from "react-toastify";
import Web3 from "web3";

const web3 = new Web3(Web3.givenProvider);

const Header = () => {
  const { walletConnected, userAddress } = useSelector(
    (state: any) => {
      return state.user;
    }
  );
  const accessToken = Storage.accessToken;

  const { library, deactivate } = useWeb3React();

  const [show, setShow] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [showDisconnect, setShowDisconnect] = useState(false);

  useEffect(() => {
    if (accessToken) {
      fetchUserInfo();
    }
  }, []);

  const fetchUserInfo = async () => {
    Admin.fetchAdminInfo().then(async (result) => {
      if (result && result.data) {
        const currentWallet = localStorage.getItem(StoreageKey.walletType);
        const chainIdWalletConnect = await web3.eth.getChainId();
        //@ts-ignore
        await wallet.setProvider(+currentWallet);
        if (library && currentWallet) {
          await wallet.reconnect(+currentWallet, library);
        }
        store.dispatch(login({ address: result.data.walletAddress }, web3.utils.toHex(chainIdWalletConnect.toString())));
      }
    });
  };

  const closeModal = () => {
    setShow(false);
  };

  const onMenuClick = () => {
    setOpenDrawer(!openDrawer);
  };

  const onCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const handleWalletConnect = async () => {
    if (walletConnected) {
      try {
        await wallet.disconnect();
        deactivate();
        localStorage.removeItem(StoreageKey.walletType);
        localStorage.removeItem("royale-admin-accessToken")
        localStorage.removeItem("royale-admin-refreshToken")
        setShowDisconnect(!showDisconnect)
        toast.info("Wallet disconnected")
      } catch (e) {}
    } else {
      setShow(true);
    }
  };

  const history = useHistory();
  const GoToPage = (arr: any) => {
    history.push(arr);
  };

  return (
    <>
      <div className="header-wrap">
        <div className="container">
          <div className="header-nav">
            <div className="header-navbar">
              <div className="header-logo">
                <a onClick={() => GoToPage("/")}>
                  <img src={FooterIcon.default} alt="" />
                </a>
              </div>
              <div className="headermenu">
                <ul>
                  <li>
                    <a onClick={() => GoToPage("/")} style={{ color: "white" }}>
                      Meme list
                    </a>
                  </li>
                  {/* // {!walletConnected && (
                  //   <li>
                  //     <a
                  //       onClick={() => GoToPage('/')}
                  //       style={{ color: "white" }}
                  //     >
                  //       Waiting list
                  //     </a>
                  //   </li>
                  // )}
                  // <li>
                  //   <a
                  //     onClick={() => GoToPage(`${Paths.approvedList}/?approved=true`)}
                  //     style={{ color: "white" }}
                  //   >
                  //     Approved list
                  //   </a>
                  // </li>
                  // <li className="dropdown">
                  //   <a
                  //     onClick={() => GoToPage(Paths.safelist)}
                  //     style={{ color: "white" }}
                  //   >
                  //     SafeList
                  //   </a>
                  // </li>
                  // <li>
                  //   <a
                  //     onClick={() => GoToPage(Paths.authUsers)}
                  //     style={{ color: "white" }}
                  //   >
                  //     Authorized users
                  //   </a>
                  // </li> */}
                </ul>
              </div>
            </div>
          </div>
          <div className="header-bottom-row">
            <div className="header-bottom-right1">
              <ul>
                <li>
                  {walletConnected && userAddress && (
                    <div className="cursor" onClick={()=>setShowDisconnect(!showDisconnect)}>
                      {userAddress[0]}
                      {userAddress[1]}
                      {userAddress[2]}
                      {userAddress[3]}
                      {userAddress[4]}
                      {userAddress[5]}
                      ...
                      {userAddress[userAddress.length - 4]}
                      {userAddress[userAddress.length - 3]}
                      {userAddress[userAddress.length - 2]}
                      {userAddress[userAddress.length - 1]}
                    </div>
                  )}
                  {!walletConnected && (
                    <div className="cursor" style={{color:"#fff"}} onClick={handleWalletConnect}>
                      Connect Wallet
                    </div>
                  )}
                  {showDisconnect && (
                    <div className="disconnect-container">
                      <label onClick={() => handleWalletConnect()}>
                        Disconnect Wallet
                      </label>
                    </div>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="header-wrap-mobile">
        <div className="mobile-logo">
          <img src={FooterIcon.default} alt="Royale" />
        </div>
        <div className="royale-amount">
          {walletConnected && (
            <div
              className="cursor"
              style={{color:"#fff"}}
              onClick={() => setShowDisconnect(!showDisconnect)}
            >
              {userAddress[0]}
              {userAddress[1]}
              {userAddress[2]}
              {userAddress[3]}
              {userAddress[4]}
              {userAddress[5]}
              ...
              {userAddress[userAddress.length - 4]}
              {userAddress[userAddress.length - 3]}
              {userAddress[userAddress.length - 2]}
              {userAddress[userAddress.length - 1]}
            </div>
          )}
          {!walletConnected && (
            <div className="cursor" style={{color:"#fff"}} onClick={handleWalletConnect}>
              Connect Wallet
            </div>
          )}
          {showDisconnect && (
            <div className="disconnect-container">
              <label onClick={() => handleWalletConnect()}>
                Disconnect Wallet
              </label>
            </div>
          )}
        </div>
        <div
          role="button"
          className={`hamburger-menu  ${
            openDrawer ? "menu-icon-hide " : "menu-icon-show"
          }`}
          onClick={onMenuClick}
        >
          <img src={headerHamburger.default} alt="open" />
        </div>
      </div>
      <Modal show={show} closeModal={closeModal}>
        <LoginModalContainer show={show} closeModal={closeModal} />
      </Modal>
      <Drawer openDrawer={openDrawer} onClose={onCloseDrawer} />
    </>
  );
};

export default Header;
