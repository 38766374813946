import React from 'react';
import './btn-close.scss';

export type BtnCloseProps = {
  onClose: () => void;
  showClose: boolean;
};

const BtnClose: React.FC<BtnCloseProps> = ({ showClose, onClose }) => {
  return (
    <div className={'close-container'} onClick={onClose}>
      <div className={`line-1 ${showClose ? 'line1-show-animation' : 'line1-close-animation'}`}></div>
      <div className={`line-2 ${showClose ? 'line2-show-animation' : 'line2-close-animation'}`}></div>
    </div>
  );
};

export default BtnClose;
