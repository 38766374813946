import styled from "styled-components";
import {Color, Shadow} from "../../../utils";


export const Wrapper = styled.div`
  width: 100%;
  background: ${Color.bg2};
  padding: 40px 17px;
  font-family: 'Cairo',sans-serif;
  border-radius: 12px;
  max-width: 1320px;
`;

export const Title = styled.div<{ asButton?: boolean }>`
  background: linear-gradient(223.88deg, rgba(255, 255, 255, 0) 1.79%, rgba(255, 255, 255, 0.1) 98.47%);
  backdrop-filter: blur(8px);
  width: fit-content;
  border-radius: 4px;
  padding: 8px 15px 15px;
  margin-left: 80px;
  font-family: 'Cairo',sans-serif;
  cursor: ${(props) => (props.asButton ? 'pointer' : '')};;
  
  img {
    margin-right: 24px;
  }
  
  h3 {
    display: flex;
  }
`;

export const Rectangle = styled.div`
  background: conic-gradient(from 180deg at 50% 50%, #EEEEEE 0deg, #FFFEE2 14.87deg, rgba(255, 255, 255, 0.950883) 25.67deg, #DF68FD 38.19deg, rgba(255, 255, 255, 0.815523) 53deg, #B7EEE5 72.26deg, #20282E 122.18deg, #29353C 138.07deg, rgba(255, 255, 255, 0.596267) 145.34deg, #C9FBFE 162.04deg, #79A2F2 175.13deg, rgba(255, 255, 255, 0.741036) 186.54deg, #79A2F2 199.54deg, #5D23FD 222.78deg, #FFFFFF 247.79deg, #51555E 320.65deg, #699CFF 343.05deg, #FFFFFF 348.79deg, #79A2F2 354.77deg, #FFFFFF 360deg);
  box-shadow: ${Shadow.whiteShadow};
  width: 70px;
  height: 3px;
  position: absolute;
  bottom: 0;
  left: calc(50% - 70px/2 + 0.5px);
`;

export const H3 = styled.h3`
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.1em;
  font-weight: 600;
  margin-bottom: 0;
  color: ${Color.white};
  text-shadow: ${Shadow.whiteShadow};
  font-family: inherit;
`;
