import { configNetwork } from "./constants";

export const getContractAddress = () => {
  return ContractAddress[configNetwork];
};

export const ContractAddress = {
  mainnet: {
    poolFactory: "0xc78F17A0FD19d0AC805c42B958118bd4dc7781F9",

    mDai: "0x6522765f3Ad4FDb1cA04DCa5C7106c8f2fEA7d9C",
    mUsdc: "0x6522765f3Ad4FDb1cA04DCa5C7106c8f2fEA7d9C",
    mUsdt: "0x6522765f3Ad4FDb1cA04DCa5C7106c8f2fEA7d9C",

    royaReserve: "0x7fac371c6222caf705b21489a0a5600a2a773a35",

    rpToken: "0xee7C29f912760611365108aCe4AE86Bc76b8488e",
    royaleLP: "0xFDC4ca4f70C59D3Cd306BeB3Ecd83b1d1C71172c",

    rptStaking: "0x7124aEE4cD902E738e6B682F7e256aC3E591A9D8",
    mRoya: "0xA9a933E0d816B36Dbf7Da87F27AC3BC4DCC2582E",

    royaToken: "0x7eaf9c89037e4814dc0d9952ac7f888c784548db",
    royaNFT: "0x448cb15b00ced3ac47467e33493d602f34c8e77e",

    settlerLot: "0xeBF2C245B82E03D91dfE862bb7DC8EdD20fC8b44",
    merchantLot: "0x2f72918f000c2be8F34335D26c1B6f50C704D5eC",
    knightLot: "0xA3e574a3B0Fb9D86CdC115c8F7573efC6B88eD9A",
    archonLot: "0x89a63d191a1d6522E918FdbAa57Bb881604c0C5e",
    monarchLot: "0x412E4958c50B372B0Bc83fc1002619dBBF95a2FA",

    settlerNFTLot: "0xd71e1349e1A0808E921424ef620cA2Eddd3978b5",
    merchantNFTLot: "0x193890D4C52a118280dDD5183da27d139D2b790f",
    knightNFTLot: "0xfB14652B35586741bF89B6B244b731D2bB4Ad6f1",
    archonNFTLot: "0xA52c58f6a27535dD8D36787688c9eb8CC8920eE5",
    monarchNFTLot: "0xfC11c826808A55E85daA1386e013Cbf7263Bd743",

    tokenSwap: "0x62901adb942943e7af78a2aE34AA1aE6710203da",
  },
  sepolia: {
    poolFactory: "0x8b7Da36C79b6c5b2De65929E613FBBe32Cf3008b",
    royaHolderProfile: "0xD2Fa8932AD2985242BaE38EEc78d4bFb91e63fB0",

    mDai: "0x14c8C719bFde4DE67D34f4b0a6c56A79548de89C",
    mUsdc: "0x14c8C719bFde4DE67D34f4b0a6c56A79548de89C",
    mUsdt: "0x14c8C719bFde4DE67D34f4b0a6c56A79548de89C",

    royaReserve: "0x543D175A2cFAb9ced91760A451058Bc4902aA238",

    rpToken: "0xa479108372F2f3bB95D5C630B79Cb6eA6cC5BdA3",
    royaleLP: "0xE877fa26e433a87f80F4063F8a1Dca212eC757bc",

    rptStaking: "0x515443166c9e735781f3D5018bEF462a79005BAb",
    mRoya: "0x0000000000000000000000000000000000000000",

    royaToken: "0x8E8440E119B530cd7E4db9C462B6E8601c613ab1",
    royaNFT: "0x6E7a02150919691a7a6f9d3b1668494aE85d16e6",

    settlerLot: "0x0B9d6d91002a155732B88c034223648a33b2cAdC",
    merchantLot: "0xB7a661C93aab113d5073CD0706Fa2B61692a8313",
    knightLot: "0x45520d4Be96d87E414Ddae867a8Af5C3FA3e6191",
    archonLot: "0xA52918993753fb6894Db3Ac24dB32b52ea4210b2",
    monarchLot: "0xA6d01a1E9Ca58AF290272e129a199F7627a19e78",

    settlerNFTLot: "0xd71e1349e1A0808E921424ef620cA2Eddd3978b5",
    merchantNFTLot: "0x193890D4C52a118280dDD5183da27d139D2b790f",
    knightNFTLot: "0xfB14652B35586741bF89B6B244b731D2bB4Ad6f1",
    archonNFTLot: "0xA52c58f6a27535dD8D36787688c9eb8CC8920eE5",
    monarchNFTLot: "0xfC11c826808A55E85daA1386e013Cbf7263Bd743",
    
    tokenSwap: "0x0000000000000000000000000000000000000000",
  },
};

export const apiUrl = "https://testing-launchpad.royale.finance/api";

// export const TierAddress = {
//   Tier1:"0xBe8f2B95fAa9Ce06f122B20e05F3c98D696d5746",
//   Tier2: "0xA6329b20FD6Fdd4CD6acEeCC6B70F7B6e2288caB",
//   Tier3: "0x2200F901a146Fb2295Fc79fA7bcA0eEb13A49Dcc",
//   Tier4: "0xAB75d37A2d71C1A73f55e4d22D35B12DDA67909e",
//   Tier5: "0xEcFd76eBeC950671F7a938B74561eF7F94F25848"
// }
