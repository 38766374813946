import styled from "styled-components";
import {Color} from "../../utils";

export const Wrapper = styled.div`
  width: 100%;
  background: ${Color.bg2};
  padding: 50px;
  font-family: 'Cairo',sans-serif;
  border-radius: 12px;
  max-width: 1320px;
`;

export const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 40px;
  
  h3 {
    font-family: 'Cairo';
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    letter-spacing: 0.1em;
    color: ${Color.white};
    margin-bottom: 0;
  }
`;

export const Th = styled.th`
  padding: 32px 40px 32px 0;
  color: #9AA1AC;
  font-family: 'Cairo';
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  text-align: left;

  &.center {
    text-align: center;
  }
  
`;

export const Table = styled.table`
  width: 100%;
`;

export const Tr = styled.tr<{ header?: boolean }>`
  border-bottom: ${(props) => (props.header ? `1px solid ${Color.cyanLight}` : null)};
`;
