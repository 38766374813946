import styled from "styled-components";
import { ButtonContainer } from "../../account/pool-list/modals/style";
import {Color} from "../../../utils";

export const Input = styled.div`
  padding-top: 16px;
  
  .input-title {
    font-family: 'Cairo';
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    color: #FFFFFF;
    opacity: 0.5;
  }
  
  label {
    width: 100%;
  }
  
  input {
    background: rgba(0, 0, 0, 0.12);
    border-radius: 20px;
    font-family: 'Cairo';
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    color: #FFFFFF;
    width: 100%;
    padding: 11px 30px;
    margin-top: 7px;
  }
`;

export const ButtonCt = styled(ButtonContainer)`
  padding-top: 70px;
`;

export const Header = styled.div`
  font-weight: 700;
  font-family: inherit;
  font-size: 20px;
  line-height: 37px;
  color: ${Color.white};
  padding-bottom: 26px;
  border-bottom: 1px solid #4F5258;
  margin-bottom: 30px;
`;
