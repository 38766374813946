import gql from 'graphql-tag';

export const GET_NONCE_BY_WALLET_ADMIN = gql`
  query ($data: GetNonceByWalletInput!) {
    getNonceByWalletAdmin(data: $data) {
      nonce
    }
  }
`;

export const AUTH_BY_WALLET_ADMIN = gql`
  mutation ($data: AuthByWalletAddressInput!) {
    authByWalletAddressAdmin(data: $data) {
      accessToken
      refreshToken
    }
  }
`
