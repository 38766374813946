/* eslint-disable @typescript-eslint/no-explicit-any */
import { plainToInstance } from 'class-transformer';
import { get } from 'lodash';

import { Api } from '../../../lib/network';
import { BaseModel } from '../base';

import {AUTH_BY_WALLET_ADMIN, GET_NONCE_BY_WALLET_ADMIN} from './queries';

export class WalletModel extends BaseModel {
  id: number;
  email: string;
  status: string;

  static fromJson(json: any) {
    return plainToInstance(WalletModel, json);
  }
  static fromJsonArray(arrJson: any[]) {
    return arrJson ? arrJson.map((x) => WalletModel.fromJson(x)) : [];
  }

  static async getNonceByWalletAdmin(wallet: any) {
    const { data, ...rest } = await Api.request(GET_NONCE_BY_WALLET_ADMIN, {
      data: { wallet },
    });

    return {
      ...rest,
      results: get(data, 'getNonceByWalletAdmin.nonce', '')
    };
  }

  static async authByWalletAdmin(signature: any, address: any) {
    const { data, ...rest } = await Api.request(AUTH_BY_WALLET_ADMIN, {
      data: {
        // payload
        signature,
        wallet: address,
      },
    });

    return {
      ...rest,
      results: get(data, 'authByWalletAddressAdmin', {})
    };
  }
}
