import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import userReducer from "./user.reducer";
import gameReducer from "./game.reducer";

const rootReducer = combineReducers({
  user: userReducer,
  games: gameReducer,
});

const store = createStore(rootReducer,applyMiddleware(thunk));

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;
