import store from "../reducers/store";
import {GET_GAMES_SUCCESS, SET_GAME_APPROVE_SUCCESS, SET_GAME_DECLINE_SUCCESS} from "./constant";

export interface GameModel {
  asset: string,
  description: string,
  email: string
  id: number,
  isActive: boolean,
  isApproved: boolean
  name: string,
  pitchDeckLink: string,
  raiseAmount: string
  status: string
  telegram: string,
  twitter: string,
  thumbnail: string,
  // videoLink: string
  userId: number,
  websiteUrl: string,
  createdAt: Date,
  user?: {
    id: number,
    walletAddress: string,
  },
  totalSafelist: number;
  poolInfo? : PoolInfo;
}

export interface PoolInfo {
  tokenPrice: number;
  totalTokens: number;
  tokenAddress: string;
  tokenSymbol: string;
  type: boolean;
  totalRaise: number;
  minRaise: number;
  startTime: string;
  endTime: string;
  min1: number;
  max5: number;
  ratio: number;
}

interface gatGamesSuccess {
  type: typeof GET_GAMES_SUCCESS,
  games: GameModel[]
}

interface approveGameSuccess {
  type: typeof SET_GAME_APPROVE_SUCCESS,
  games: GameModel[]
}

interface declineGameSuccess {
  type: typeof SET_GAME_DECLINE_SUCCESS,
  games: GameModel[]
}


export const getGames = (games: GameModel[]): gatGamesSuccess => {
  return {
    type: GET_GAMES_SUCCESS,
    games
  }
}

export const approveGame = (id: number): approveGameSuccess => {
  const games = store.getState().games.games.map((i: GameModel) => {
    if (+i.id === +id) {
      i.status = "Approved";
      i.isApproved = true;
    }
    return i;
  })

  return {
    type: SET_GAME_APPROVE_SUCCESS,
    games
  }
}

export const declineGame = (id: number): declineGameSuccess => {
  const games = store.getState().games.games.map((i: GameModel) => {
    if (+i.id === +id) {
      i.status = "Declined";
      i.isApproved = false;
    }
    return i;
  })

  return {
    type: SET_GAME_DECLINE_SUCCESS,
    games
  }
}
