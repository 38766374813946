import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'


const polygonUrl = `https://rpc.ankr.com/polygon/0xd387098b3ca4c6d592be0ce0b69e83be86011c50`
const mumbaiUrl = `https://rpc.ankr.com/polygon_mumbai/0xd387098b3ca4c6d592be0ce0b69e83be86011c50`

const environment = process.env.REACT_APP_ENV || 'dev'

export const supportedNetworkIds = environment === 'production' ? [137, 56, 1] : [80001, 97, 5]

interface IRPCMap {
  [chainId: number]: string;
}
export const rpcUrl: IRPCMap = environment === 'production' ? { 137: polygonUrl } : { 80001: mumbaiUrl }

export const injected = new InjectedConnector({ supportedChainIds: supportedNetworkIds })

export const walletConnect = new WalletConnectConnector({
  rpc: rpcUrl,
  chainId: supportedNetworkIds[0],
  qrcode: true,
})

export const connectorsByName = {
  Injected: injected,
  TrustWallet: injected,
  WalletConnect: walletConnect,
}

export const mainnetUrl = 'https://polygonscan.com'
export const testNetUrl = 'https://mumbai.polygonscan.com'

export const mainnetInstructions = [
  {
    label: 'networkName',
    title: 'Polygon - Mainnet',
    textToCopy: 'Polygon - Mainnet',
  },
  {
    label: 'newRpcUrl',
    title: 'https://polygon-rpc.com/',
    textToCopy: 'https://polygon-rpc.com/',
  },
  {
    label: 'chainId',
    title: '137',
    textToCopy: '137',
  },
  {
    label: 'symbol',
    title: 'MATIC',
    textToCopy: 'MATIC',
  },
  {
    label: 'blockExplorerUrl',
    title: 'https://polygonscan.com/',
    textToCopy: 'https://polygonscan.com/',
  },
]

export const testnetInstructions = [
  {
    label: 'networkName',
    title: 'Mumbai - Testnet',
    textToCopy: 'Mumbai - Testnet',
  },
  {
    label: 'newRpcUrl',
    title: 'https://rpc-mumbai.matic.today',
    textToCopy: 'https://rpc-mumbai.matic.today',
  },
  {
    label: 'chainId',
    title: '80001',
    textToCopy: '80001',
  },
  {
    label: 'symbol',
    title: 'MATIC',
    textToCopy: 'MATIC',
  },
  {
    label: 'blockExplorerUrl',
    title: 'https://mumbai.polygonscan.com',
    textToCopy: 'https://mumbai.polygonscan.com',
  },
]
