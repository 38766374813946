import styled from "styled-components";
import {Color} from "../../../../../utils";

export const ContactWrapper = styled.div`
  background: rgba(0, 0, 0, 0.6);
  box-shadow: inset 0px 4px 11px rgba(0, 0, 0, 0.16), inset 0px 0px 2px rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(8px);
  border-radius: 24px;
  padding: 22px 45px;
`;

export const H3 = styled.h3`
  font-family: 'Cairo',serif;
  font-weight: 700;
  font-size: 32px;
  line-height: 56px;
  letter-spacing: 0.01em;
  color: ${Color.white};
`;

export const ContactRowWrapper = styled.div`
  display: flex;
`;

export const Cell = styled.div`
  width: 40%;
  font-family: 'Cairo',serif;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  padding: 24px 0;
  border-bottom: 1px solid #23272E;
  color: ${Color.darkPrimary};


  &.link a {
    color: ${Color.white};
    text-decoration-line: underline !important;
  }
  
  &.preferred {
    font-weight: 700;
    width: 20%;
    text-shadow: 0px 0px 8px rgba(255, 255, 255, 0.45);
    color: ${Color.white};
  }
`;
