/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosRequestConfig } from 'axios';
import { print } from 'graphql/language/printer';
import { get, isEmpty, isNil } from 'lodash';

import { endpoint } from './graphqlClient';
import { Storage } from '../../utils/storage';

import graphQLClient from './graphqlClient';

interface ParsedReponseType {
  success?: boolean;
  error?: string | null;
  errorCode?: number | null;
  data?: any;
}

const BASE_URL = endpoint;

export class Api {
  private static get headers() {
    const accessToken = Storage.accessToken;
    const headers = accessToken
      ? { authorization: `Bearer ${accessToken}` }
      : undefined;
    return headers;
  }

  static async request(query: any, params: any = {}) {
    try {
      const data = await graphQLClient.request(
        print(query),
        params,
        Api.headers
      );
      return { data };
    } catch (error) {
      const message =
        get(error, 'response.errors[0].message', null) ??
        get(error, 'response.errors[0].extensions.response.message', null) ??
        'Something went wrong.';
      return {
        error: message,
        errorCode: get(
          error,
          'response.errors[0].extensions.exception.status',
          0
        ),
      };
    }
  }

  static formatError(value: string, errorCode: number) {
    if (isNil(value)) return null;

    let message = value.replace('Error: ', '');
    if (message.includes('jwt malformed')) {
      message = 'Your credentials were not registered in system.';
    }
    if (message.includes('jwt expired') || errorCode === 401) {
      message =
        'The session has expired. Please refresh the page and login to your account to return to the dashboard.';
    }
    if (message.slice(-1) !== '.') {
      return message + '.';
    }
    return message;
  }

  static handleResponse(response: any): ParsedReponseType {
    try {
      if (response.data.error) {
        const { error } = response.data;
        const { code: errorCode, message = '', errors = [] } = error || {};
        if (!isNil(message)) {
          return {
            success: false,
            error: Api.formatError(message, errorCode),
            errorCode,
          };
        }

        if (!isEmpty(errors)) {
          const { msg } = errors[0];
          return {
            success: false,
            error: Api.formatError(msg, errorCode),
            errorCode,
          };
        }
      } else if (response.data.errors) {
        const { errors } = response.data;
        const message = get(errors[0], 'message', null);
        const errorCode = get(errors[0], 'extensions.exception.status', 0);
        return {
          error: message,
          errorCode,
        };
      } else {
        return response.data;
      }
    } catch (error) {
    }
    return { success: true, data: response.data };
  }

  static parseError(error: any): ParsedReponseType {
    return {
      error: get(error, 'response.errors[0].message', null),
      errorCode: get(
        error,
        'response.errors[0].extensions.response.statusCode',
        null
      ),
    };
  }

  static createConfiguration() {
    return { ...axios.defaults, headers: Api.headers };
  }

  static async refreshAccess() {
    const refreshToken = Storage.refreshToken;
    if (!refreshToken) return { success: false };
    try {
      const { success, data } = Api.handleResponse(
        await axios.post(BASE_URL + '/auth/refresh-access', { refreshToken })
      );
      if (success) {
        Storage.accessToken = data.accessToken;
        Storage.refreshToken = data.refreshToken;
      }
      return { success };
    } catch (error) {
      return { success: false };
    }
  }

  static async get(path: string, params: any = {}): Promise<ParsedReponseType> {
    let query = '';
    if (params) {
      query = '?';
      const keys = Object.keys(params);
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        query += `${key}=${encodeURIComponent(params[key])}`;
        if (i < keys.length - 1) {
          query += '&';
        }
      }
    }

    try {
      const requestPath = BASE_URL + path + query;
      const response = await axios.get(
        requestPath,
        Api.createConfiguration() as AxiosRequestConfig
      );
      return Api.handleResponse(response);
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        const { success } = await Api.refreshAccess();
        if (success) {
          return await Api.get(path, params);
        }
      }

      throw error;
    }
  }

  static async post(
    path: string,
    params: any = {}
  ): Promise<ParsedReponseType> {
    try {
      const response = await axios.post(
        BASE_URL + path,
        params,
        Api.createConfiguration() as AxiosRequestConfig
      );
      return Api.handleResponse(response);
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        const { success } = await Api.refreshAccess();
        if (success) {
          return await Api.post(path, params);
        }
      }

      return Api.parseError(error);
    }
  }

  static async uploadFiles(
    files: any,
    callback?: (percent: number) => void
  ): Promise<ParsedReponseType> {
    try {
      const form = new FormData();
      form.append('file', files[0]);
      form.append('fileName', files[0].name);

      const config: any = Api.createConfiguration();
      config['headers'] = {
        ...config['headers'],
        'Content-Type': 'multipart/form-data',
      };

      const response = await axios.post(BASE_URL + '/upload', form, {
        ...config,
        onUploadProgress: (event) => {
          callback?.(Math.round((event.progress ?? 0) * 100.0));
        },
      });
      return response;
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        const { success } = await Api.refreshAccess();
        if (success) {
          return await Api.uploadFiles(files, callback);
        }
      }

      throw error;
    }
  }

  static async put(path: string, params: any = {}): Promise<ParsedReponseType> {
    try {
      const response = await axios.put(
        BASE_URL + path,
        params,
        Api.createConfiguration() as AxiosRequestConfig
      );
      return Api.handleResponse(response);
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        const { success } = await Api.refreshAccess();
        if (success) {
          return await Api.put(path, params);
        }
      }

      throw error;
    }
  }

  static async delete(
    path: string,
    params: any = null
  ): Promise<ParsedReponseType> {
    try {
      const response = await axios.delete(BASE_URL + path, {
        headers: Api.headers,
        data: params,
      } as AxiosRequestConfig);
      return Api.handleResponse(response);
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        const { success } = await Api.refreshAccess();
        if (success) {
          return await Api.delete(path, params);
        }
      }

      throw error;
    }
  }
}
