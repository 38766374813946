import gql from "graphql-tag";

export const ADD_GAME = gql`
  mutation addGame($data: AddGameInput!) {
    addGame(data: $data)
  }
`;

export const GAME = gql`
  query findGameById($id: Int!) {
    findGameById(id: $id) {
      description
      email
      id
      isActive
      isApproved
      name
      pitchDeckLink
      raiseAmount
      status
      telegram
      twitter
      thumbnail
      # videoLink
      user {
        id
        walletAddress
      }
      totalSafelist
      poolInfo {
        tokenAddress
        tokenSymbol
        tokenPrice
        totalTokens
        startTime
        min1
        max5
      }
      websiteUrl
      createdAt
    }
  }
`;

export const GAME_LISTS = gql`
  query findGamesByAdmin {
    findGamesByAdmin {
      description
      email
      id
      isActive
      isApproved
      name
      pitchDeckLink
      raiseAmount
      status
      telegram
      twitter
      thumbnail
      # videoLink
      user {
        id
        walletAddress
      }
      websiteUrl
      createdAt
    }
  }
`;

export const APPROVE_GAME = gql`
  mutation approveGame($id: Int!) {
    approveGame(id: $id)
  }
`;

export const DECLINE_GAME = gql`
  mutation declineGame($id: Int!) {
    declineGame(id: $id)
  }
`;

export const FIND_SAFELIST = gql`
  query findSafelistById($id: Int!) {
    findSafelistById(id: $id) {
      id
      name
      email
      twitter
      tier
      user {
        walletAddress
      }
    }
  }
`;

export const FIND_PRIVATE_WHITELIST = gql`
  query findPrivateWhitelist($id: Int!) {
    findPrivateWhitelist(gameId: $id) {
      id
      walletAddress
      tier
      deposit
      claim
    }
  }
`;

export const FIND_PUBLIC_WHITELIST = gql`
  query findPublicWhitelist($id: Int!) {
    findPublicWhitelist(gameId: $id) {
      id
      walletAddress
      tier
      deposit
      claim
    }
  }
`;
